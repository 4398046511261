<template lang="pug">
v-sheet(max-width="400px")
  t-list(min-width="300px")
    slot
    v-divider
    v-subheader UI DIALOG
    t-list-item(title="Mode Fullscreen")
      v-switch(inset v-model="s.fullscreen")
    t-list-item(title="Header Bold")
      v-switch(inset v-model="s.boldHeader")
    t-list-item(title="Options Icons/Buttons" vZv-t="title")
      v-switch(inset v-model="value.implicitClient")
    t-list-item(title="Wizard Style")
      v-switch(inset v-model="s.wizard")
    t-list-item(title="Animations")
      v-switch(inset v-model="s.animations")
    t-list-item(title="Sounds")
      v-switch(inset v-model="s.sounds")
    t-list-item(title="Themes")
      v-switch(inset v-model="s.themes")
    t-list-item(title="Dark")
      v-switch(inset v-model="s.dark")
    v-subheader UI OPTIONS
    slot(name="options_icons" v-if="!value.implicitClient")
      t-list-item(v-if="value.closLeft != undefined" title="Close Right/Left")
        v-switch(inset v-model="value.closLeft")
      t-list-item(v-if="value.moreBtn != undefined" title="Show More")
        v-switch(inset v-model="value.moreBtn")
      t-list-item(v-if="value.implicitClient != undefined" title="Show Client")
        v-switch(inset v-model="value.implicitClient")
      t-list-item(v-if="value.implicitTrash != undefined" title="Show Trash")
        v-switch(inset v-model="value.implicitTrash")
      t-list-item(v-if="value.cartEditButton != undefined" title="Show Edit")
        v-switch(inset v-model="value.cartEditButton")
      t-list-item(v-if="value.printBtn != undefined" title="Show Print")
        v-switch(inset v-model="value.printBtn")
    template(v-else)
      t-list-item(v-if="value.trashOrOptions != undefined" title="Primary Button")
        v-switch(inset v-model="value.trashOrOptions")
      t-list-item(v-if="value.trashOrOptions != undefined" title="Secondary Button")
        v-switch(inset v-model="value.trashOrOptions")

    v-divider
    v-subheader UI ACTIONS
    t-list-item(v-if="s.enableActions != undefined" title="Enable Actions")
      v-switch(inset v-model="s.enableActions")
    t-list-item(v-if="s.actionsMenu != undefined" title="Actions Menu")
      v-switch(inset v-model="s.actionsMenu")
    v-list-group(v-if="value.bottomBtn != undefined" :value="false" no-action Zsub-group)
      template(v-slot:activator)
        v-list-item-content
          v-list-item-title Secondary Actions
      v-list-item( v-for="i in value.dirs.bottomBtn" :key="i.name" Zlink)
        v-list-item-title(v-text="i")
        v-list-item-icon
          v-icon(v-if="i && i.indexOf('mdi') >= 0" v-text="i")
          template(v-else) OFF

    //- t-list-item(v-if="value.bottomBtn != undefined" title="Button")
      v-btn-toggle(v-model="value.bottomBtn" dense borderless)
        v-btn(:value="i" v-for="i in value.dirs.bottomBtn")
          v-icon(v-if="i && i.indexOf('mdi') >= 0") {{i}}
          template(v-else) OFF
        //- v-btn(value="edit"  icon): v-icon() mdi-pencil
        //- v-btn(value="clear"  icon): v-icon( color="red") mdi-trash-can
        //- v-btn(value="print"  icon): v-icon() mdi-printer

    v-divider
    v-subheader UI FORM
    t-list-item(title="Input Filled" v-if="s.InputFilled != undefined"): v-switch(inset v-model="s.InputFilled")
    t-list-item(title="Edit Inline/Dialog" v-if="s.editDialog != undefined"): v-switch(inset v-model="s.editDialog")
    t-list-item(title="ZI Style" v-if="s.ZIStyle != undefined"): v-switch(inset v-model="s.ZIStyle")
    slot(name="footer")

</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {},
  props: {
    value: {
      type: Object,
    },
  },
  data: () => ({
    s: {
      implicitClient: false,
      ZIStyle: false,
      boldHeader: false,
      hideHeaderOnZoom: false,
      fullscreen: true,
      wizard: false,
      animations: false,
      sounds: false,
      dark: false,
      enableActions: true,
      actionsMenu: true,
      themes: ['rally', 'kaspi', 'shopify', 'square'],
    },
    // quantity: 1,

  }),

  computed: {
    ...mapState({
    }),
  },

  methods: {
    ...mapActions({

    }),
  },
}
</script>
<style lang="stylus"></style>
