<template lang="pug">
//- t-card(:color="color" :showClose="notEmptyCart" v-on="$listeners" body-height="300px" :editMode="editMode")
t-window(v-bind="$attrs" v-on="$listeners" min-height="300px")
  template(slot="header") Чек #3
  template(slot="header-actions-prepend")

    t-btn.mr-1(@click="$emit('route','contacts')" fab icon)  mdi-account-plus
    //- t-btn.mr-1(v-if="!settings.cartEditButton && notEmptyCart" v-model="editMode" fab icon) mdi-pencil
    t-btn.mr-1(v-if="notEmptyCart" fab icon @click.stop="$emit('route','check')")  mdi-printer
    //- t-btn.mr-1(v-if="notEmptyCart && !settings.implicitTrash && !settings.trashOrOptions" color="red" @click="clearCart(); editMode=false" fab icon)  mdi-trash-can

  template(slot="header-more")
    CartOptions(v-model="settings")
      t-list-item(title="Multicart")
        v-switch(inset v-model="settings.multicart")
  template(slot="subheader")
    CartClientActions(:show="notEmptyCart" @route="$emit('route','client')" @editMode="editMode = !editMode" :implicitClient="settings.implicitClient" :trashOrOptions="settings.trashOrOptions")
      template(slot="button-more")
        CartOptions(v-model="settings")
          template(slot="head")
            v-subheader Модификаторы Карты
            t-list-item(icon="mdi-tray-plus" title="Добавить товар")
            t-list-item(icon="mdi-gift" title="Добавить подарок")
            t-list-item(icon="mdi-account" title="Добавить клиента")
            t-list-item(icon="mdi-sale" title="Применить скидку")
            t-list-item(icon="mdi-note" title="Добавить заметку")
            t-list-item(icon="mdi-account-tie" title="Добавить сотрудника")
            t-list-item(icon="mdi-tag" title="Рекомендации к товару" disabled)
            v-subheader Корзина
            t-list-item(icon="mdi-store" title="Доставка товара")
            t-list-item(icon="mdi-send" title="Отправить корзину")
            t-list-item(icon="mdi-floppy" title="Сохранить корзину")
            t-list-item(icon="mdi-printer" title="Пречек")

    v-tabs.cart-tabs(v-model="multicheckTabs" color="primary" background-color="dark" v-if="settings.multicart" Zshow-arrows="false" height="36")
      v-tabs-slider(color="primary")
      v-tab Чек 1
      v-tab Чек 2
      v-tab: v-icon mdi-plus
          //- t-list-item(title="Share cart" @click.stop="$emit('fullscreen',2)"): v-icon(color="primary") mdi-share

  template(slot="default" slot-scope="{ events: { scrollToBottom } }")
    CartList(:editMode="editMode" :implicitTrash="settings.implicitTrash" @scrollToBottom="scrollToBottom" v-on="$listeners")
  template(slot="footer" v-if="!nobasket")
    slot
      Basket.mt-2(@click="$emit('route','payment',true)" Zno-icon emptyDisabled no-empty no-icon)
        template(slot="left-content" slot-scope="{isEmpty,disabled}" v-if="settings.cartEditButton")
          v-btn(color="primary" v-if="!isEmpty" x-large :disabled="disabled || isEmpty" :outlined="!editMode" @click="editMode = !editMode"): v-icon mdi-pencil
        template(slot="menu" slot-scope="{menu, hide}")
          DialogCartActions(:value="menu" @input="hide")

          //- v-btn(color="red" v-else x-large :disabled="disabled || isEmpty" @click="clearCart(); editMode = false"): v-icon mdi-trash-can

</template>
<script>
import { mapState, mapActions } from 'vuex'

import Basket from 'T@/components/cart/Basket.vue'
import CartList from 'T@/components/cart/_parts/CartList.vue'
import CartClientActions from 'T@/components/cart/_parts/CartClientActions.vue'
import CartOptions from 'T@/components/cart/_parts/CartOptions.vue'
import DialogCartActions from 'T@/pages/kassa/DialogCartActions.vue'

export default {
  components: {
    Basket,
    CartList,
    CartClientActions,
    DialogCartActions,
    CartOptions,
  },
  props: {
    nobasket: {
      type: Boolean,
      default: undefined,
    },
    item: {
      type: Object,
      default: null,
      required: false,
    },
    // editMode: {
    //   type: Boolean,
    //   default: false,
    //   required: false,
    // },
    color: {
      type: String,
      default: undefined,
    },
  },
  data: () => ({
    editMode: false,
    multicheckTabs: 0,
    settings: {
      implicitClient: true,
      implicitTrash: false,
      trashOrOptions: false,
      cartEditButton: false,
      printBtn: false,
      multicart: false,
      fullscreen: false,
      cartButton: '',
    },
  }),
  computed: {
    ...mapState({
      cart: (state) => state.tjtheme.cart,
    }),
    notEmptyCart() { return this.cart.length > 0 },
    total() {
      return this.cart.reduce((acc, i) => acc + i.commodity.total, 0)
    },
  },
  created() {
  },
  methods: {
    ...mapActions({
      clearCart: 'tjtheme/clearCart',
      addItem: 'tjtheme/addItem',
    }),
    editItem(item) {
      console.log('editItem', item)
    },
  },
}
</script>
<style lang="stylus">
.theme--dark.v-divider {
  border-color: rgba(150, 150, 150, 0.12) !important;
}

.cart-tabs
  .v-tab
    text-transform: none !important
    letter-spacing: 0
    font-size 16px
    // padding 0 6px

  // .v-slide-group__next, .v-slide-group__prev
    // min-width 24px
    // flex 0 1 24px

  // .v-slide-group__prev
    // display none !important
</style>
