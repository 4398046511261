<template lang="pug">
DialogBasketMenu(v-bind="$attrs" v-on="$listeners")
  template(slot="title"): .subtitle-1 Тип операции
  template(slot="header-more")
    v-list
      v-list-item-group(v-model="settings.listStyle" active-class="border" color="indigo")
        v-list-item: v-list-item-content: v-list-item-title Accordion
        v-list-item: v-list-item-content: v-list-item-title List
        v-list-item: v-list-item-content: v-list-item-title Buttons
  template(slot="header-actions-prepend")
    v-btn(:color="reverse?'red':'grey darken-3'" rounded Zfab depressed small Zicon dark @click.stop="reverse = !reverse") Отмена
      //- v-icon(:color="!reverse?'primary':'white'") mdi-undo-variant
    //- v-checkbox.mt-0.align-baseline.d-inline-flex(v-model="reverse" right hide-details label="Возврат")
  //- template(slot="header-more")
    v-list
      v-list-item-group(v-model="settings.ziStyle" active-class="border" color="indigo")
        v-list-item: v-list-item-content: v-list-item-title Accordion
        v-list-item: v-list-item-content: v-list-item-title List
        v-list-item: v-list-item-content: v-list-item-title Buttons
    //- t-list
      v-subheader UI SETTINGS
      t-list-item(v-if="settings.ziStyle != undefined" title="ZI Style")
        v-switch(v-model="settings.ziStyle")
  v-expansion-panels(accordion flat :value="0" v-if="settings.listStyle == 0")
    v-expansion-panel(v-for="(n,z) in ziActions" :key="z")
      v-expansion-panel-header.grey--text {{z}}
      v-expansion-panel-content.pa-0
         v-row(dense)
            v-row(dense)
              t-list-item.pa-0(v-for="(i,j) in n" :key="j" :icon="reverse&&i.ret?i.ret.icon:i.icon" :title="reverse&&i.ret?i.ret.title:i.title" @click="$emit('input',false)" no-right)

  t-list(v-else-if="settings.listStyle==1" Zsubheader Zdense)
    template(v-for="(n,z, k) in ziActions")
      v-divider(v-if="k > 0" Zinset)
      v-subheader(color="primary") {{z}}
      template(v-for="(i,j) in n" )
        t-list-item(:icon="reverse&&i.ret?i.ret.icon:i.icon" :class="reverse?'red--text':undefined" :title="reverse&&i.ret?i.ret.title:i.title" no-right @click="$emit('input',false)")
        v-divider(inset v-if="j < n.length - 1")

  v-list(v-else)
    v-list-item(:input-value="tile.active" v-for='tile in actions' :key="tile.in")
      v-list-item-avatar
        v-avatar(size='32px' tile='')
          img(:src='`https://cdn.vuetifyjs.com/images/bottom-sheets/${tile.img}`')
      v-list-item-content.mr-2
        v-btn(depressed outlined @click="$emit('input',false)" color="indigo") {{reverse?'Отмена ':''}} {{ tile.in }}
      v-list-item-content
        v-btn(depressed @click="$emit('input',false)" :outlined="!tile.active" color="primary") {{ tile.out }}
    //- v-list-item
      v-list-item-avatar
        v-checkbox(v-model="reverse" right)
      v-list-item-content: v-list-item-title.grey--text Возврат/Отмена
    //- v-divider
    //- t-list-item.pl-2(icon="mdi-close"  @click="$emit('input',false)" title="Закрыть" noRight)
</template>
<script>

import DialogBasketMenu from '@/TJTheme/pages/kassa/DialogBasketMenu.vue'

export default {
  components: {
    DialogBasketMenu,
  },
  data: () => ({
    reverse: false,
    settings: {
      ziStyle: 1,
      listStyle: 0,

    },
    ziActions: {
      Продажа: [
        { icon: 'mdi-account-cash', title: 'Продажа', ret: { icon: 'mdi-cash-refund', title: 'Возврат продажи' } },
        { icon: 'mdi-cash-multiple', title: 'Покупка', ret: { icon: 'mdi-cash-remove', title: 'Возврат покупки' } },
      ],
      Наличные: [
        { icon: 'mdi-cash-plus', title: 'Внесение денег' },
        { icon: 'mdi-cash-minus', title: 'Изъятие денег' },
      ],
      Склад: [
        { icon: 'mdi-arrow-left-right', title: 'Перемещение' },
        { icon: 'mdi-playlist-check', title: 'Инвентаризация' },
        { icon: 'mdi-delete-off-outline', title: 'Списание' },
      ],
    },
    actions: [

      {
        img: 'messenger.png', out: 'Продажа', in: 'Закупка', active: true,
      },
      { img: 'keep.png', in: 'Внесение Нал', out: 'Изъятие Нал' },

      { img: 'inbox.png', in: 'Приход', out: 'Перемещение' },
      { img: 'hangouts.png', in: 'Инвентарь', out: 'Списание' },
      // { img: 'google.png',  },
    ],
  }),
}
</script>
<style lang="stylus">
</style>
