<template lang="pug">
v-list.py-0(flat)
  template(v-if="!cart.length")
    v-skeleton-loader(type="list-item-two-line", boilerplate)
    p.px-4.text-center.font-weight-thin Необходимо добавить хотя бы один
      br
      | товар чтобы сформировать чек
      v-btn#addBtn.mt-5(
        rounded,
        outlined,
        color="primary",
        no-caps,
        v-sound.add
        @click="addItem()"
      )
        v-icon.mr-2(size="26px") mdi-barcode
        | Добавить товар в чек
  //- transition-group(:name="settings.cart.transition", :key="animationTrigger" tag="div" Zafter-enter="$emit('scrollToBottom')")
  template(v-for="(item, i) in cart")
    CartItem.shaky(
      :class="{'pause':pause}"
      :item="item",
      :key="i + '-' +item.total",
      :quantity="i == 1 ? 2 : undefined",
      :editMode="editMode",
      :index="i",
      @click="editItem(item, i)",
      v-on="$listeners"
    )
    v-divider.mr-4(inset :key="'d' + i")
  //- v-divider
  template(v-if="cart.length")
    v-list-item.my-2(v-if="implicitTrash")
      v-btn.red--text(block color="grey darken-3" depressed @click="clearCart")
        v-icon(left) mdi-trash-can
        | Очистить чек
    v-btn#addBtn.py-6.justify-start(
      block,
      text,
      elevation="0",
      large,
      v-sound.add
      color="primary",
      no-caps,
      @click="addItem(); $emit('scrollToBottom')")
      v-icon.pl-2(size="26px") mdi-plus
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    CartItem: () => import('./CartItem.vue'),
  },
  props: {
    editMode: {
      type: Boolean,
    },
    implicitTrash: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    // animationTrigger: false,
    pause: true,
    // settings: {
    // cart: {
    // transition: 'list',
    // list
    // slide-x-transition
    // fade-transition
    // slide-x-reverse-transition
    // scroll-x-transition
    // expand-transition
    // scale-transition
    //   },
    // },
  }),
  computed: {
    ...mapState({
      cart: (state) => state.tjtheme.cart,
    }),
  },
  mounted() {
    this.$emit('scrollToBottom')
    setTimeout(() => { this.pause = false }, 500)
  },
  methods: {
    ...mapActions({
      clearCart: 'tjtheme/clearCart',
      addItem: 'tjtheme/addItem',
    }),
    editItem(item) {
      // console.log('editItem', item)
      this.$emit('edit', item)
    },
  },
}
</script>
<style lang="stylus">
.theme--dark.v-divider {
  border-color: rgba(150, 150, 150, 0.12) !important;
}

.shaky
  -webkit-animation: minishake-reverse .3s linear;

  &.pause
    animation-duration: 0s !important;

</style>
