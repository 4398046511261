<template lang="pug">
t-dialog#cartActions(:value="value" inset @input="$emit('input',$event)")
  v-list
    .d-flex.justify-space-between
      v-subheader: slot(name="title") Menu
      div
        slot(name="header-actions-prepend")
        v-menu
          template(v-slot:activator="{ on, attrs }")
            t-btn(icon v-bind="attrs" v-on="on") mdi-dots-vertical
          slot(name="header-more")
    v-divider
    slot
    v-divider
    t-list-item(icon="mdi-close" @click="$emit('input',false)" title="Закрыть" noRight)
    //- v-list
      v-list-item
        v-list-item-avatar(Zrounded): v-icon.primary(dark) mdi-folder
        v-list-item-title Продажа
      v-list-item
        v-list-item-avatar(rounded): v-icon.primary(dark) mdi-folder
        v-list-item-title Возврат
</template>
<script>

export default {
  components: {
  },
  props: ['value'],
  data: () => ({
  }),
}
</script>
<style lang="stylus">
</style>
