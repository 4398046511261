<template lang="pug">
t-list(v-if="client" color="indigo")
  v-divider
  //- v-subheader(inset) Клиент
  t-list-item(:title="client.name" @click.stop="$emit('route','client')" avatar="mdi-account") (777) 701-55-62
t-list.mb-2(color="transparent" v-else-if="implicitClient")
  t-collapse(:value="show")
    v-list-item
      v-row(dense)
        v-col(cols="6")
          v-btn.primary--text(block color="grey darken-3" large depressed @click="$emit('editMode')")
            v-icon(left) mdi-pencil
            | Исправить
            //- v-btn.primary--text(block color="grey darken-3" depressed large Zoutlined @click.stop="$emit('route','client')")
              v-icon(left) mdi-account-plus
              | Клиент
        v-col(cols="6")
          v-btn.red--text(block color="grey darken-3" large depressed @click.stop="clearCart") Очистить
            v-icon(right) mdi-trash-can
          //- v-btn.primary--text(block color="grey darken-3" large depressed @click="showMore =!showMore") Опции
            v-icon(right) mdi-dots-vertical
          t-window(v-model="showMore" Zlight, Zoffset-y, Zoffset-overflow Zdialog Zfullscreen)
            template(slot="header") Опции
            slot(name="button-more")

</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {},
  props: {
    implicitClient: {
      type: Boolean,
      default: undefined,
    },
    trashOrOptions: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    showMore: false,
    // quantity: 1,
  }),

  computed: {
    ...mapState({
      client: (state) => state.tjtheme.client,
    }),
  },

  methods: {
    ...mapActions({
      clearCart: 'tjtheme/clearCart',
    }),
  },
}
</script>
<style lang="stylus"></style>
